<template>
    <list-page
        page-title="Properties"
        page-icon="mdi-office-building"
        collection-name="property"
        :items-per-page="20"
        add-dialog-title="Add Property"
        is-filter
    />
</template>

<!--<template>-->
<!--    <Page-->
<!--        title="Properties"-->
<!--        icon="mdi-office-building"-->
<!--    >-->
<!--        not a correct page-->
<!--        <smart-table-->
<!--            v-if='tableItems.length'-->
<!--            :headers="tableHeaders"-->
<!--            :items="tableItems"-->
<!--            :items-per-page="20"-->
<!--            class="elevation-1"-->
<!--            @click:row="clickRow"-->
<!--            dense-->
<!--            isFilter-->
<!--        />-->
<!--    </Page>-->
<!--</template>-->

<script>
import ListPage from '../../../components/pages/List';



export default {
    name: 'PropertyList',
    components: { ListPage },

};
</script>

<style scoped></style>
